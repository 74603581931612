/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, { createContext, useReducer, useContext } from 'react'
import { updateGlobalState } from '../../state/globalState/actions'
import reducer, { INITIAL_STATE } from '../../state/globalState/reducers'

export const GlobalContext = createContext()

export const GlobalContextProvider = ({ children, initialGlobalData = {} }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  if (initialGlobalData && Object.values(initialGlobalData).length && !state.initialGlobalData) {
    // update the agent data from the api call
    dispatch(updateGlobalState({
      initialGlobalData
    }))
  }
  return (
    <GlobalContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContextValue = () => useContext(GlobalContext)
