import { useCallback } from 'react'
import { useErrorContextValue } from '../context/ErrorContext'
import { useLoaderContextValue } from '../context/LoaderContext'
import { useServiceContextValue } from '../context/ServiceContext'
import { camelCase } from '../helpers/utility'
import serviceCall from '../service'
import { ERROR_ACTIONS } from '../state/errorState/actions'
import { LOADER_ACTIONS } from '../state/loaderState/actions'

export const useServiceRequest = (dispatch) => {
  // dispatch method pass by the caller component so that
  // it can update the value to respected store

  // serviceContextState is the state that includes the headers
  // like authHeaders, and getGlobalErrorMessages provider function
  // so that we can handle auth related things from one place and
  // error providers from one place
  const serviceContextState = useServiceContextValue()

  // dispatchLoader is the dispatch function to update the state of loader
  // function on the loader state so that we can access the loader for api calls
  // from once place and can show loader on page or button on click so that any developer can
  // focus on the show loader only not on set or reset for the api request
  const [, dispatchLoader] = useLoaderContextValue()

  // dispatchError is the dispatch the error for the service request call to error provider
  // state so that we can access all api related errors from one place and also have a
  // single source of errors for the project.
  // we are calling this function with the error status and error for the action type
  const [, dispatchError] = useErrorContextValue() || [{}, () => {}]

  // callApi is the service function call, that we are using to communicate with
  // the backed/server api calls, and this function call take action type, payload
  // basically this takes the actions that we define for our reducers
  // e.g
  // this is the format of action type for the service call
  // const getDetails = (payload) => ({
  //   type: 'GET_DETAIL_REQUEST',
  //   payload: {
  //     method: 'GET/POST/DELETE/PUT', // any one at time
  //     header: {
  //       'Content-Type': 'multipart/form-data', // in case you need to provide multipart or want to override the content-type,
  //     },
  //     url: '/api/end/point'
  //   }
  // })
  const callApi = useCallback(async ({ type, payload }) => {
    // payload contains the following format as defined above
    //   payload: {
    //     method: 'GET/POST/DELETE/PUT', // any one at time
    //     query: {}, // go with the get call
    //     params: {}, // contains the url variable part
    //     body: {}, // contains the data sent in post method call
    //     noGlobalHeader: false,
    //     header: {
    //       'Content-Type': 'multipart/form-data', // in case you need to provide multipart or want to override the content-type,
    //     },
    //     url: '/api/end/point'
    //   }
    const { headers = {}, ...restPayload } = payload

    const actionType = type.replace(/_REQUEST/g, '')
    const camelCaseActionType = camelCase(actionType)

    // getGlobalErrorMessages: is the function use to pass the status code and error
    // response to the the caller component and provider so that provider/parent
    // can manager the error strings on the basis of error codes and the error response
    const { getGlobalErrorMessages = () => {} } = serviceContextState

    // this update loader with respect to action type
    // like GET_DETAIL_REQUEST then loader becomes GET_DETAIL_LOADER
    // and can be access this value inside the caller component/ Parent component
    // with the help of useLoaderContextValue() returns the following status [{ GET_DETAIL_LOADER }]
    dispatchLoader({
      type: LOADER_ACTIONS.LOADER_UPDATE,
      payload: {
        [`${actionType}_LOADER`]: true
      }
    })
    // // auth service call or normal service call
    let res = {}
    try {
      const customHeaders = {
        ...(serviceContextState.headers || {}),
        ...headers
      }
      res = await serviceCall({
        ...restPayload,
        headers: customHeaders
      })
    } catch (error) {
      res = error
    }
    // update the store in both cases so that this data key
    // can be accessible from any component/child component
    dispatch({
      type: `${actionType}`,
      payload: {
        [`${camelCaseActionType}Data`]: [200, 201, 202].includes(res.status) ? res.data : {}
      }
    })
    dispatchError({
      type: ERROR_ACTIONS.ERROR_UPDATE,
      payload: {
        [`${actionType}_ERROR`]: ![200, 201, 202].includes(res.status),
        [`${camelCaseActionType}Status`]: ([200, 201, 202].includes(res.status))
          ? 'success'
          : getGlobalErrorMessages(res.status, res.data),
        [`${camelCaseActionType}StatusCode`]: res.status,
        [`${camelCaseActionType}ErrorData`]: ([200, 201, 202].includes(res.status))
          ? undefined
          : res.data
      }
    })
    dispatchLoader({
      type: LOADER_ACTIONS.LOADER_UPDATE,
      payload: {
        [`${actionType}_LOADER`]: false
      }
    })
  }, [])

  return [callApi]
}
