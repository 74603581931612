export function urlMapper (url, params = {}) {
  let urlString = url
  Object.keys(params).forEach(key => {
    urlString = urlString.replace(`{${key}}`, params[key])
    return urlString
  })
  return urlString
}

export function queryBuilder (finalFilter) {
  const queryArray = []
  Object.keys(finalFilter).forEach((key) => {
    const keyValue = finalFilter[key]
    let query = ''
    const identifier = queryArray.length ? '&' : '?'
    if (Array.isArray(keyValue)) {
      keyValue.forEach((value) => {
        query += `${query ? '&' : ''}${key}=${encodeURIComponent(value)}`
      })
    } else if (keyValue) {
      query += `${key}=${encodeURIComponent(keyValue)}`
    }
    if (query) {
      queryArray.push(`${identifier}${query}`)
    }
  })
  return queryArray.join('')
}

export const camelCase = (str = '') => {
  // str  => GET_DATA_REQUEST => getDataRequest
  return str.toLowerCase().replace(/_([a-z])/g, function (g) { return g[1].toUpperCase() })
}

export function mapArrayElementsKeysToObjectKeys (keys) {
  const stateToBeReset = {}
  keys.forEach(key => {
    stateToBeReset[key] = undefined
  })
  return stateToBeReset
}
