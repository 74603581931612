import { GLOBAL_ACTIONS } from './actions'

export const INITIAL_STATE = {

}

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case GLOBAL_ACTIONS.GLOBAL_STATE_UPDATE:
      return {
        ...state,
        ...payload
      }
    default:
      return {
        ...state
      }
  }
}
export default reducer
